/**
 * Mixin to be used for using service.
 *
 * @returns {object}
 */
import {
  movingCorporateNL,
  movingCorporateBE,
  movingCorporateES,
  movingInternationalNL,
  movingInternationalBE,
  movingInternationalES,
  movingConsumerNL,
  movingConsumerBE,
  movingConsumerES
} from 'chimera/moving/service'

export default {
  computed: {
    /**
     * @returns {*}
     */
    service () {
      return this.$store.state.service
    },

    /**
     * @returns {boolean}
     */
    serviceIsConsumer () {
      return this.arrayIncludesService([
        movingConsumerNL.id,
        movingConsumerBE.id,
        movingConsumerES.id
      ], this.service.id)
    },

    /**
     * @returns {boolean}
     */
    serviceIsInternational () {
      return this.arrayIncludesService([
        movingInternationalNL.id,
        movingInternationalBE.id,
        movingInternationalES.id
      ], this.service.id)
    },

    /**
     * @returns {boolean}
     */
    serviceIsCorporate () {
      return this.arrayIncludesService([
        movingCorporateNL.id,
        movingCorporateBE.id,
        movingCorporateES.id
      ], this.service.id)
    }
  },

  methods: {
    /**
     * @param {Array} array
     * @param {string} serviceId
     * @returns {boolean|*}
     */
    arrayIncludesService (array, serviceId) {
      if (!serviceId) {
        return false
      }

      return array.includes(serviceId)
    }
  }
}
